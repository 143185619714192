<template>
  <div class="list-plans">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-6" v-for="plan in plans" :key="plan.id">
        <VPlanCard
          class="v-plan-card mb-3"
          @click.native="toCheckout(plan)"
          :condensed="true"
          :plan="plan"
          :hide-image="true"
          :always-collaspable="false"/>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_PLANS } from '@/graphql/queries'
import VPlanCard from '@/modules/checkout/components/VPlanCard'

export default {
  name: 'ListPlans',
  components: { VPlanCard },
  apollo: {
    plans: GET_PLANS
  },
  methods: {
    toCheckout (plan) {
      console.log('plan', plan)
      this.$router.push({ name: 'Checkout', params: { plan_id: plan.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-plan-card {
  border-radius: 8px;
  transition: transform .12s linear;
  &:hover {
    cursor: pointer;
    transform: scale(1.04);
  }
}
</style>

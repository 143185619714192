<template>
  <div class="home">
    <div class="container">
      <section>
        <div class="row">
          <div class="col-12">
            <div class="top-heading-offer">
              <div>
                <span class="ads-subtitle">O maior jornal<br />do Espírito Santo.</span>
                <p class="ads-info text-muted font-weight-light mt-3">
                  Seja assinante do maior e melhor jornal do estado
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="row">
          <div class="col-12 mt-5">
            <h5>Todos os <strong>planos</strong></h5>
            <div style="width: 40px" class="divider-4px mb-3 bg-warning"></div>
            <ListPlans />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ListPlans from '@/modules/store-front/components/ListPlans'

export default {
  name: 'Home',
  components: { ListPlans }
}
</script>

<style lang="scss" scoped>
.top-heading-offer {
  padding: 100px 0 40px;
  .ads-subtitle {
    background-color: #fadd00;
    font-size: 2.5em;
    line-height: 1.3em;
  }
  .ads-title {
    //display: inline-block;
    font-size: 60px;
  }
  .ads-info {
    font-size: 1.5em;
    line-height: 1.2em;
  }
}
</style>
